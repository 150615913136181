<template>
  <div class="main container product-page">
    <Breadcrumb :list="breadcrumbList" />
    <div class="content">
      <div class="left">
        <h2 class="title">案例</h2>
        <p class="animate__animated animate__fadeIn desc">
          ShuangzhaoDB其拥有大吞吐量，超低延时，高可靠性等的特点，适用于各大型系统及应用；其成熟的架构设计，让其得以在各个场景游刃有余，如：在快速预警，实时数仓，实时决策等。
        </p>
        <section
          v-for="(item, i) in caseList"
          :key="item.id"
          class="animate__animated animate__fadeIn"
        >
          <h3 class="sub-title hidden-md-and-up">{{ item.title }}</h3>
          <div v-if="i % 2" class="case">
            <div>
              <h3 class="sub-title hidden-sm-and-down">{{ item.title }}</h3>
              <p>{{ item.content }}</p>
            </div>
            <img :src="item.imgUrl" />
          </div>
          <div v-else class="case">
            <img :src="item.imgUrl" />
            <div>
              <h3 class="sub-title hidden-sm-and-down">{{ item.title }}</h3>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </section>
      </div>
      <ProdMenu class="right hidden-sm-and-down" activeIndex="6" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import ProdMenu from "@/components/ProdMenu";
import "animate.css";
export default {
  name: "Case",
  components: {
    Breadcrumb,
    ProdMenu,
  },
  data() {
    return {
      breadcrumbList: [
        { id: 1, path: "/", label: "ShuangzhaoDB" },
        { id: 2, path: "/product", label: "产品" },
        { id: 3, path: "", label: "案例" },
      ],
      caseList: [
        {
          id: 1,
          title: "保险通赔运营平台",
          imgUrl: require("@/assets/images/case1.jpg"),
          content:
            "保险通赔运营平台，通过ShuangzhaoDB实现实时全盘掌控预警信息，实时监控理赔业务中众多环节；协助各业务员科学、更快处理各项工作；让客户体验不一般的服务速度以及更好的服务体验。",
        },
        {
          id: 2,
          title: "5G – 微数据中心的边缘计算和灵活定价",
          imgUrl: require("@/assets/images/case2.png"),
          content:
            "PCRF 平台：电信级高可用的实时数据管理平台,提供快速实时的费用预警，虚拟化和容器化方式实时应对流量集中和迁移。",
          sub: [
            {
              id: 22,
              title: "成果：",
              content:
                "允许用户按需使用, 实时升级, 费用预警虚拟化和容器化方式实时应对流量集中和迁移快速相应新兴的业务，把握市场机会",
            },
          ],
        },
        {
          id: 3,
          title: "实时决策-交易反欺诈应用",
          imgUrl: require("@/assets/images/case3.png"),
          content:
            "交易反欺诈应用其中采用的是ShuangzhaoDB打造的业务规则引擎；其用于金融交易事中分析处理，高速摄取交易流数据，快速处理其中的复杂的反欺诈规则，快速响应。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/product.less";
section {
  &:first-of-type .case img {
    height: 200px;
  }
  .case {
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      height: 250px;
    }
  }
}
@media screen and(max-width:991px) {
  .desc {
    margin-bottom: 20px;
  }
  section {
    &:first-of-type .case img {
      height: 80px;
    }
    .case {
      img {
        height: 120px;
      }
    }
  }
}
</style>